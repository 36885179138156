import React, { useMemo, type ReactNode } from "react"
import clsx from "clsx"
import Container from "@ecom/ui/components/Container"
import { items } from "./helpers"

import * as styles from "./moreBenefitsInvitefriend.module.scss"

type MoreBenefitsRedesignProps = {
  title?: ReactNode
  orderNum?: string
  variant: "inviteFriend" | "cashback" | "rostics" | "irbis"
}

export const MoreBenefitsInvitefriend = ({
  title = "Как принять участие",
  variant = "inviteFriend",
  orderNum,
}: MoreBenefitsRedesignProps) => {
  const itemsList = useMemo(
    () =>
      items[variant].map(({ svg, title: itemTitle, subTitle }, i) => (
        <div key={i} className={clsx(styles.item, styles[`item${i + 1}`])}>
          <h3 className={styles.itemTitle}>{itemTitle}</h3>
          <p className={styles.itemSubTitle}>{subTitle}</p>
          {svg}
        </div>
      )),
    [variant]
  )

  return (
    <section className={styles.section} id="moreBenefitsInvitefriend " data-exclude={orderNum}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.textBlock}>
            <h2 className={styles.title}>{title}</h2>
          </div>
          <div className={styles.wrap}>{itemsList}</div>
        </div>
      </Container>
    </section>
  )
}
